import React, { useEffect } from "react";
import { useState } from "../../state";
import cx from "classnames";
import css from "./index.module.css";

const getTimeFromTimer = timer => {
  return `${Math.floor(timer / 60)}:${
    `${timer % 60}`.length === 1 ? "0" + (timer % 60) : timer % 60
  }`;
};

const noOpKeys = ["id", "numTexts", "listGroup", "info"];

export default function Breadcrumbs({ timer }) {
  const [state] = useState();
  const component = window.location.pathname.slice(1);
  const sections = Object.keys(state).filter(el => !noOpKeys.includes(el));
  const currentSectionNumber = sections.indexOf(component) + 1;
  const totalSections = sections.length;
  const [intervalTimer, setInternalTimer] = React.useState(timer);

  useEffect(() => {
    const renderTimer = setInterval(() => {
      setInternalTimer(prevTime => prevTime - 1);
    }, 1000);
    return () => {
      clearInterval(renderTimer);
    };
  }, []);

  if (intervalTimer <= 0) {
    window.location.href = window.location.hostname.includes("localhost")
      ? "http://localhost:3000"
      : `https://${window.location.hostname}`;
  }

  return (
    <div className={css.header}>
      <div className={css.breadcrumbs}>
        {sections.map((el, index) => (
          <div
            key={el}
            className={cx(css.crumb, {
              [css.selected]: index < currentSectionNumber
            })}
          />
        ))}
      </div>
      <div className={css.flex}>
        <div
          className={css.questionsLeft}
        >{`Question ${currentSectionNumber} of ${totalSections}`}</div>
        {intervalTimer < 180 && (
          <div className={css.timeLeft}>
            Time remaining: {getTimeFromTimer(intervalTimer)}
          </div>
        )}
      </div>
    </div>
  );
}
