export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      dateCompleted
      firstName
      lastName
      score
      phoneNumber
      answers
      listGroup
      numTexts
      firstContact
      lastNudge
      location
      unreadEmails
      status
      consultant
      appointmentDate
      doctor
      motivation
      childMotivation
      marketingPlatform
      stopper
      consult
      concierge
      ptqResubmitingTime
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($id: ID!) {
    getPractice(id: $id) {
      id
      displayName
      logo
      locations {
        id
        displayName
        fullName
        logoUrl
        isChildPractice
      }
    }
  }
`;

export const generateOtp = /* GraphQL */ `
  query GenerateOtp(
    $phoneNumber: String!
    $email: String!
    $practice: String!
  ) {
    generateOtp(phoneNumber: $phoneNumber, email: $email, practice: $practice) {
      message
      token
    }
  }
`;
export const verifyOtp = /* GraphQL */ `
  query VerifyOtp($token: ID!, $otp: String!) {
    verifyOtp(token: $token, otp: $otp) {
      message
      isValid
    }
  }
`;
