import React from "react";
import css from "./index.module.css";
import congrats from "../../images/congrats.png";

export default function Congrats(props) {
  return (
    <div>
      <div className={css.wrapper}>
        <img src={congrats} alt="congrats" className={css.img} />
        <div className={css.text}>{props.section.prompt}</div>
      </div>
    </div>
  );
}
