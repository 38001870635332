import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const toTitleCase = (text = "") =>
  text
    ? text
        ?.trim()
        ?.split(" ")
        ?.map((item) => item[0]?.toUpperCase() + item?.slice(1))
        .join(" ")
    : "";

export const generateNextRecallDate = (
  interval,
  lastRecallDate,
  unit = "days"
) => {
  return moment(moment(lastRecallDate).add(+interval, unit)).isoWeekday() === 7
    ? moment(lastRecallDate)
        .add(+interval, unit)
        .add(1, "days")
        .format("L")
    : moment(moment(lastRecallDate).add(+interval, unit)).isoWeekday() === 6
    ? moment(lastRecallDate)
        .add(+interval, unit)
        .add(2, "days")
        .format("L")
    : moment(lastRecallDate)
        .add(+interval, unit)
        .format("L");
};

export const generateRecallList = () => {
  let lastRecallDate =
    moment().get("hours") < 5
    ? moment().add(-1, "days").format("L")
    : moment().format("L");
  let newRecall = Array.from({ length: 7 }, () => 1)?.map((item) => {
    lastRecallDate = generateNextRecallDate(item, lastRecallDate);
    return {
      id: uuidv4(),
      notes: "",
      type: "Text Recall",
      isCompleted: false,
      dueDate: lastRecallDate,
      recallCompletedDate: "",
    };
  });
  return newRecall;
};
