import { Button, Dialog, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import DialogTitle from "../DialogTitle";
import css from "./index.module.css";
import cx from "classnames";
import API, { graphqlOperation } from "@aws-amplify/api";
import { verifyOtp } from "../../graphql/queries";
import { useState } from "../../state";

const useStyles = makeStyles((theme) => ({
  dialogCustomizedWidth: {
    width: "750px",
    minWidth: "750px",
    maxWidth: "750px",
  },
  outlined: {
    padding: "4px 10px 4px 10px",
  },
  confirmButton: {
    backgroundColor: "#9bcc64",
    marginTop: "1.5em",
    color: "white",
    "&:hover": {
      backgroundColor: "#9bcc64",
    },
  },
}));

const OTPModal = ({
  open,
  onHide,
  onConfirm,
  token,
  resendOTP = () => {},
  timer,
  setTimer = () => {},
  isLoading
}) => {
  const classes = useStyles();
  const [state] = useState();
  const [otp, setOTP] = React.useState();
  const [error, setError] = React.useState("");

  useEffect(() => {
    let interval;
    if (token) {
      setTimer(15);
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return --prev;
          else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [token]);

  const onVerifyOTP = async () => {
    await API.graphql(
      graphqlOperation(verifyOtp, {
        token,
        otp,
      })
    ).then((res) => {
      if (res?.data?.verifyOtp?.isValid) onConfirm();
      else setError(res?.data?.verifyOtp?.message);
    });
  };

  useEffect(() => {
    setError("");
  }, [otp]);

  return (
    <div>
      <Dialog
        open={!!open}
        disableBackdropClick
        classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
        onClose={() => onHide(false)}
      >
        <DialogTitle onClose={() => onHide(false)}>
          Verification Code
        </DialogTitle>
        <div className={css.dialogContent}>
          <div className={css.description}>
            At {state?.practiceDisplayName}, your safety and security are top priority!
            Please enter the Verification Code sent to your email and phone.
          </div>
          <div className={css.inputContainer}>
            <p className={css.subHeading}>Enter Code</p>
            <TextField
              placeholder={"OTP code"}
              variant="outlined"
              value={otp}
              type={"number"}
              onChange={(e) =>
                String(e.target.value).length < 5
                  ? setOTP(e.target.value)
                  : () => {}
              }
              className={classes.messageArea}
              InputProps={{
                classes: {
                  root: classes.multiLine,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <p
              className={timer ? cx(css.resend, css.disabled) : css.resend}
              onClick={timer ? () => {} : resendOTP}
            >
              Resend Code {timer ? `(${timer}s)` : ""}
            </p>
            {error && <p className={css.errorMessage}>{error}</p>}
          </div>
          <div className={css.footerContainer}>
            <Button
              variant="outlined"
              onClick={onVerifyOTP}
              className={classes.confirmButton}
              disabled={String(otp).length !== 4 || isLoading}
            >
              {"Submit"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OTPModal;
