import React, { createRef, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useHistory, useParams } from "react-router-dom";
import cx from "classnames";

import { useState } from "../../state";

import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import logo from "../../images/cloud9ignite.png";
import moment from "moment";
import {
  formatIncompletePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";

import css from "./index.module.css";
import OTPModal from "../OTPModal";
import API, { graphqlOperation } from "@aws-amplify/api";
import { generateOtp } from "../../graphql/queries";
import { onSubmit } from "../../lib";

const useStyles = makeStyles((theme) => ({
  multilineColor: {
    color: "white",
    fontSize: "1.5em",
  },
  floatingLabelFocusStyle: {
    color: "white",
  },
  border: {
    color: "white",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  FormLabelRoot: {
    color: "white",
    dispaly: "inline",
  },
  RadioGroupRow: {
    display: "inline-block",
    marginLeft: "2em",
  },
}));

const CssTextField = withStyles({
  root: {
    width: "100%",
    margin: "8px 0",
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderBottomColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const WhiteCheckBox = withStyles({
  root: {
    color: "white",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const present = (item) => {
  return !!item;
};

const StyledRadio = withStyles({
  root: {
    color: "white",
    "&.Mui-checked": {
      color: "white",
    },
  },
})(Radio);

const validateBirthday = (day, month, year) => {
  let inputDate = moment(`${month}/${day}/${year}`, "M/D/YYYY", true);
  let age;
  if (!inputDate.isValid())
    return "Please enter a valid birth date including 4 digit year";
  age = moment().diff(inputDate, "years");
  if (age <= 0 || age > 100) return "Please check if birthdate is accurate";
  return "";
};

const getErrorMessage = (state, forChild, isProspecting) => {
  const { info } = state;
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  let phone = info.mobile && parsePhoneNumberFromString(info.mobile, "US");
  if (forChild) {
    if (forChild && (!info?.resPartyFirstName || !info?.resPartyLastName))
      return "Please enter the Responsible Party's name";
    if (
      !(
        present(info.firstName) &&
        present(info.lastName) &&
        present(info.resPartyFirstName) &&
        present(info.resPartyLastName) &&
        present(info.resPartyTitle) &&
        present(info.dob_day) &&
        present(info.dob_month) &&
        present(info.dob_year) &&
        (isProspecting || present(info.email)) &&
        present(info.mobile)
      )
    )
      return "Please complete all form fields!";
  } else {
    if (
      !(
        present(info.firstName) &&
        present(info.lastName) &&
        present(info.dob_day) &&
        present(info.dob_month) &&
        present(info.dob_year) &&
        (isProspecting || present(info.email)) &&
        present(info.mobile)
      )
    )
      return "Please complete all form fields!";
  }
  if (!phone || phone.nationalNumber.length !== 10)
    return "Please check phone number. Enter area code and 7 digit number.";
  else if (!isProspecting && !emailRegex.test(info.email))
    return "Please enter valid email address.";
  return validateBirthday(info.dob_day, info.dob_month, info.dob_year);
};

export default function Welcome({ isProspecting }) {
  const [state, dispatch] = useState();
  const history = useHistory();
  const [forChild, setForChild] = React.useState(false);
  const [popoverMessage, setPopoverMessage] = React.useState("");
  const [showPopover, setShowPopover] = React.useState(false);
  const [showPrivayPolicy, setShowPrivayPolicy] = React.useState(false);
  const [otpModal, setOtpModal] = React.useState(false);
  const [token, setToken] = React.useState(false);
  const [timer, setTimer] = React.useState(false);
  const { platform, id } = useParams();

  const classes = useStyles();

  const monthRef = createRef();
  const dayRef = createRef();
  const yearRef = createRef();
  const emailRef = createRef();
  const responsiblePartyRef = createRef();

  const handleChange = (event) => {
    dispatch({
      type: "info",
      value: { ...state.info, [event.target.id]: event.target.value },
    });
  };
  const handleChangeByName = (event) => {
    dispatch({
      type: "info",
      value: { ...state.info, [event.target.name]: event.target.value },
    });
  };

  const handlePhoneNumber = (event) => {
    let phoneNumber = formatIncompletePhoneNumber(event.target.value, "US");
    dispatch({
      type: "info",
      value: { ...state.info, [event.target.id]: phoneNumber },
    });
  };

  const handleDOB = (event) => {
    if (event.target.value.length === 2 && event.target.id === "dob_month") {
      dayRef.current.focus();
    }

    if (event.target.value.length === 2 && event.target.id === "dob_day") {
      yearRef.current.focus();
    }

    if (event.target.id === "dob_year" && event.target.value.length === 4) {
      const { dob_day, dob_month } = state?.info || {};
      if (dob_day && event.target.value && dob_month) {
        let date = moment(`${dob_month}/${dob_day}/${event.target.value}`);
        if (date.isValid() && moment().diff(date, "years") < 18) {
          if (forChild) responsiblePartyRef.current.focus();
          else setForChild(true);
        } else {
          setForChild(false);
          if (emailRef.current) emailRef.current.focus();
        }
      }
    }

    if (
      event.target.id === "dob_day" &&
      event.target.value.length === 0 &&
      state.info.dob_day >= 0 &&
      event?.target?.value < 31
    ) {
      monthRef.current.focus();
    }

    dispatch({
      type: "info",
      value: { ...state.info, [event.target.id]: event.target.value },
    });
  };

  const handleClose = () => {
    setShowPopover(false);
  };

  const handleGetStarted = () => {
    const errorMessage = getErrorMessage(state, forChild, isProspecting);
    if (errorMessage) {
      setPopoverMessage(errorMessage);
      setShowPopover(true);
    } else setShowPrivayPolicy(true);
  };

  const resendOTP = async (thenFunc = () => {}) => {
    if (isProspecting) {
      onSubmit({ state, dispatch, isProspecting, history, platform });
    } else {
      setTimer(15);
      await API.graphql(
        graphqlOperation(generateOtp, {
          phoneNumber: state.info.mobile,
          email: state.info.email,
          practice: state?.listGroup,
        })
      )
        .then((res) => {
          setToken(res?.data?.generateOtp?.token);
          thenFunc();
        })
        .catch((err) => {
          console.error(err, "error");
        });
    }
  };

  useEffect(() => {
    if (!!state?.isProspecting !== !!isProspecting) {
      dispatch({ type: "isProspecting", value: !!isProspecting });
    }
  }, [isProspecting]);

  const blockPTQ =
    state?.blockUser &&
    (!state?.ptqResubmitingTime ||
      (!isNaN(state?.ptqResubmitingTime) &&
        moment(+state?.ptqResubmitingTime).valueOf() < moment().valueOf()));

  if (state?.status === "removed")
    return (
      <>
        <div className={css.sorryDiv}>
          <div className={css.sorry}>
            This link is expired.
            <br />
            Contact the practice for a new link.
          </div>
        </div>
      </>
    );
  if (blockPTQ)
    return (
      <>
        <div className={css.sorryDiv}>
          <div className={css.sorry}>
            Thank you. This questionnaire is already complete.
          </div>
        </div>
      </>
    );

  return (
    <div className={css.welcome}>
      <div className={css.overlay}>
        <img src={state?.logo || logo} alt="logo" className={css.logo} />
        <div className={css.inputs}>
          <div className={css.selfOrChild}>
            <span>I am completing this for my child</span>
            <WhiteCheckBox
              checked={forChild}
              value="for child"
              onChange={() => setForChild(!forChild)}
              inputProps={{
                "aria-label": "secondary checkbox",
              }}
            />
          </div>
          <div className={cx({ [css.firstName]: forChild })}>
            <CssTextField
              id="firstName"
              label={(forChild ? "Child" : "") + " First Name"}
              onChange={handleChange}
              value={state.info.firstName || ""}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
            <CssTextField
              id="lastName"
              label={(forChild ? "Child" : "") + " Last Name"}
              value={state.info.lastName || ""}
              onChange={handleChange}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
          </div>
          <div className={css.dob}>
            <div className={css.dobText}>
              <span>Date of Birth</span>
            </div>
            <CssTextField
              id="dob_month"
              label="month"
              value={state.info.dob_month || ""}
              onChange={handleDOB}
              inputRef={monthRef}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
            <CssTextField
              id="dob_day"
              label="day"
              value={state.info.dob_day || ""}
              onChange={handleDOB}
              inputRef={dayRef}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
            <CssTextField
              id="dob_year"
              label="year"
              value={state.info.dob_year || ""}
              inputRef={yearRef}
              onChange={handleDOB}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
          </div>
          {forChild && (
            <div className={cx({ [css.lastName]: forChild })}>
              <CssTextField
                inputRef={responsiblePartyRef}
                id="resPartyFirstName"
                label="Parent First Name"
                autoFocus
                onChange={handleChange}
                value={state.info.resPartyFirstName || ""}
                InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
                InputProps={{ className: classes.multilineColor }}
              />
              <CssTextField
                id="resPartyLastName"
                label="Parent Last Name"
                value={state.info.resPartyLastName || ""}
                onChange={handleChange}
                InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
                InputProps={{ className: classes.multilineColor }}
              />
              <div>
                <FormLabel classes={{ root: classes.FormLabelRoot }}>
                  Parent Title
                </FormLabel>
                <RadioGroup
                  row
                  required
                  name="resPartyTitle"
                  value={state.info.resPartyTitle || ""}
                  onChange={handleChangeByName}
                  classes={{ row: classes.RadioGroupRow }}
                >
                  <FormControlLabel
                    value="Mr."
                    classes={{ root: classes.FormLabelRoot }}
                    control={<StyledRadio />}
                    label="Mr."
                  />
                  <FormControlLabel
                    value="Mrs."
                    classes={{ root: classes.FormLabelRoot }}
                    control={<StyledRadio />}
                    label="Mrs."
                  />
                  <FormControlLabel
                    value="Ms."
                    classes={{ root: classes.FormLabelRoot }}
                    control={<StyledRadio />}
                    label="Ms."
                  />
                </RadioGroup>
              </div>
            </div>
          )}
          {!isProspecting && (
            <CssTextField
              id="email"
              inputRef={emailRef}
              label={forChild ? "Parent's Email" : "Email"}
              value={state.info.email || ""}
              onChange={handleChange}
              InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
              InputProps={{ className: classes.multilineColor }}
            />
          )}
          <CssTextField
            id="mobile"
            label={forChild ? "Parent's Mobile" : "Mobile"}
            value={
              state.info.mobile
                ? formatIncompletePhoneNumber(state.info.mobile, "US")
                : ""
            }
            onChange={handlePhoneNumber}
            InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
            InputProps={{ className: classes.multilineColor }}
          />
        </div>
        <div className={css.go}>
          <div
            onClick={blockPTQ ? () => {} : handleGetStarted}
            className={css.linkText}
          >
            Get Started
          </div>
        </div>
        <Popover
          id="privacy"
          open={showPrivayPolicy}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={css.popover}>
            <div>
              By continuing, you agree to receive text messages from Cloud 9
              Ignite, and agree to our{" "}
              <a
                href="https://toothfairytermsandconditions.s3-us-west-2.amazonaws.com/TermsOfUse.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href="https://toothfairytermsandconditions.s3-us-west-2.amazonaws.com/PrivacyPolicy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Msg frequency varies. Msg & data rates may apply.
            </div>

            <div className={css.continueButton}>
              <Button
                onClick={() => {
                  resendOTP(setOtpModal(!isProspecting));
                }}
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
            </div>
          </div>
        </Popover>
        <Popover
          id={"popover"}
          open={showPopover}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={css.popover}>{popoverMessage}</div>
        </Popover>
      </div>
      {otpModal && (
        <OTPModal
          onConfirm={() => history.push(`/${Object.keys(state)[0]}`)}
          onHide={() => {
            setShowPopover(false);
            setOtpModal(false);
            setShowPrivayPolicy(false);
          }}
          open={otpModal}
          resendOTP={resendOTP}
          token={token}
          timer={timer}
          setTimer={setTimer}
        />
      )}
    </div>
  );
}
