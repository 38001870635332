import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Question from "../Question";
import { useState } from "../../state";
import css from "./index.module.css";

export default function InputText(props) {
  const question = props.section;
  const [state, dispatch] = useState();

  function handleChange(event) {
    dispatch({ type: question.id, value: event.target.value });
  }

  return (
    <div>
      <FormControl component="fieldset">
        <Question text={question.prompt} helpText={question.helper} />
        <TextField
          variant="outlined"
          multiline
          rowsMax="5"
          inputProps={{ maxLength: 200 }}
          className={css.textarea}
          onChange={handleChange}
          value={state[question.id]}
        />
      </FormControl>
    </div>
  );
}
