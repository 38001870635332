import React from "react";
import TextField from "@material-ui/core/TextField";
import css from "./index.module.css";
import { useState } from "../../state";

export default function Other({ handleOther, question }) {
  const [state] = useState();

  return (
    <TextField
      variant="outlined"
      multiline
      value={state[`other_${question.id}`]}
      className={css.textarea}
      label="Please explain"
      onChange={e => handleOther(e.target.value)}
    />
  );
}
