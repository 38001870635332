import React, { useEffect, useRef } from "react";
import { onSubmit } from "../../lib";
import { useState } from "../../state";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import logo from "../../images/cloud9ignite.png";
import css from "./index.module.css";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import Toogle from "../Toogle";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import OTPModal from "../OTPModal";
import { getErrorMessage } from "./lib";
import { withStyles } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import { generateOtp } from "../../graphql/queries";

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: "8px !important",
  },
  button: {
    boxShadow: "3px 5px 4px -3px grey",
    transition: "padding 0.25s ease-out",
    "&:hover": {
      boxShadow: "3px 5px 4px -3px grey",
      paddingLeft: 50,
      paddingRight: 50,
      transition: "padding 0.25s ease-out",
    },
  },
}));

const MarketingLandingPage = ({ isProspecting }) => {
  const classes = useStyles();
  const [state, dispatch] = useState();
  const [otpModal, setOTPModal] = React.useState(false);
  const [isChild, setChild] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [parentFirstName, setParentFirstName] = React.useState("");
  const [parentLastName, setParentLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [error, setError] = React.useState("");
  const history = useHistory();
  const { platform } = useParams();
  const parentFirstNameRef = useRef(null);
  const parentLastNameRef = useRef(null);
  const parentMobileRef = useRef(null);
  const childFirstNameRef = useRef(null);
  const childLastNameRef = useRef(null);
  const childMobileRef = useRef(null);
  const [timer, setTimer] = React.useState(false);
  const [token, setToken] = React.useState(false);

  const onKeyDown = ({ code }, nextRef) => {
    if (code === "Enter" || code === "ArrowDown") {
      if (nextRef.current) {
        nextRef.current.focus({ preventScroll: false });
      }
    }
  };

  const onConfirm = () => {
    const errorMessage = getErrorMessage({
      isChild,
      firstName,
      lastName,
      parentFirstName,
      parentLastName,
      mobile,
    });
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setOTPModal(true);
      resendOTP();
    }
  };

  const onSubmitForm = () => {
    setLoading(true);
    onSubmit({
      isProspecting,
      state: {
        info: {
          firstName,
          lastName,
          resPartyFirstName: parentFirstName,
          resPartyLastName: parentLastName,
          mobile,
        },
        listGroup: state?.listGroup,
        consultant: state?.consultant,
      },
      dispatch,
      history,
      platform,
      setLoading,
    });
  };

  const onChangeTextField = (e, setState) => {
    setState(e.target.value);
  };

  const resendOTP = async (thenFunc = () => {}) => {
    setTimer(15);
    await API.graphql(
      graphqlOperation(generateOtp, {
        phoneNumber: mobile,
        email: "",
        practice: state?.listGroup,
      })
    )
      .then((res) => {
        setToken(res?.data?.generateOtp?.token);
        thenFunc();
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };

  useEffect(() => {
    dispatch({ type: "isProspecting", value: !!isProspecting });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background:
          "linear-gradient(135deg, rgba(253,106,255,1) 0%, rgba(115,69,224,1) 100%)",
      }}
    >
      <Paper
        variant="outlined"
        elevation={3}
        sx={{
          maxWidth: 500,
          px: { xs: 1, sm: 2 },
          py: 3,
          width: { xs: "calc( 100% - 10px )", sm: "90%" },
          boxSizing: "border-box",
          borderRadius: 10,
          boxShadow: "0px 0px 15px -1px grey inset",
          my: 1,
        }}
      >
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              mx: 1,
              mt: 1,
              mb: { xs: 0, sm: 1 },
              width: "25ch",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            sx={{
              // display: "flex",
              justifyContent: "space-between",
              px: { xs: 1.2, sm: 2 },
            }}
          >
            <Box sx={{ mr: { xs: 0, sm: 2 }, ml: { xs: 1, sm: 0 } }}>
              <img src={state?.logo || logo} alt="logo" className={css.logo} />
            </Box>
            <Box>
              <h2 className={css.heading}>Welcome!</h2>
              <p className={css.description}>
                Sign up & get the smile you've always dreamed of!
              </p>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#924de7",
              mt: 2,
              py: 2,
              px: 1,
              boxSizing: "border-box",
              textAlign: "center",
              color: "#fff",
              fontSize: 20,
              fontStyle: "italic",
              fontWeight: 700,
              letterSpacing: 1.5,
              width: { xs: "calc( 100% + 16px )", sm: "calc( 100% + 32px )" },
              marginLeft: { xs: -1, sm: -2 },
            }}
          >
            <span className={css.blink}>
              Hurry, offer ends {moment().endOf("month").format("L")}
            </span>
          </Box>
          <Box sx={{ mt: { xs: 2, sm: 3 }, mx: 2, color: "#7746e1f2" }}>
            <Toogle
              label="Patient is a child under the age of 18"
              value={isChild}
              onChange={(e) => {
                setChild(e.target.checked);
                onKeyDown(
                  { code: "Enter" },
                  !!e.target.checked ? parentFirstNameRef : childFirstNameRef
                );
              }}
            />
          </Box>
          {!!isChild && (
            <React.Fragment>
              <InputField label="Parent's First Name">
                <TextField
                  id="outlined-textarea"
                  inputRef={parentFirstNameRef}
                  autoFocus
                  onKeyDown={(e) => onKeyDown(e, parentLastNameRef)}
                  placeholder="Parent's First Name"
                  onChange={(e) => onChangeTextField(e, setParentFirstName)}
                  value={parentFirstName}
                  sx={{
                    width: "100% !important",
                    boxShadow: "3px 5px 4px -3px grey",
                    borderRadius: 2,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                />
              </InputField>
              <InputField label="Parent's Last Name">
                <TextField
                  id="outlined-textarea"
                  inputRef={parentLastNameRef}
                  onKeyDown={(e) => onKeyDown(e, parentMobileRef)}
                  placeholder="Parent's Last Name"
                  onChange={(e) => onChangeTextField(e, setParentLastName)}
                  value={parentLastName}
                  sx={{
                    width: "100% !important",
                    boxShadow: "3px 5px 4px -3px grey",
                    borderRadius: 2,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                />
              </InputField>
              <InputField label="Parent's Mobile">
                <TextField
                  id="outlined-textarea"
                  inputRef={parentMobileRef}
                  onKeyDown={(e) => onKeyDown(e, childFirstNameRef)}
                  value={formatIncompletePhoneNumber(mobile, "US")}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  placeholder="Mobile"
                  sx={{
                    width: "100% !important",
                    boxShadow: "3px 5px 4px -3px grey",
                    borderRadius: 2,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                />
              </InputField>
            </React.Fragment>
          )}
          <InputField label={`${isChild ? "Child's " : ""}First Name`}>
            <TextField
              id="outlined-textarea"
              inputRef={childFirstNameRef}
              autoFocus
              onKeyDown={(e) => onKeyDown(e, childLastNameRef)}
              placeholder={`${isChild ? "Child's " : ""}First Name`}
              onChange={(e) => onChangeTextField(e, setFirstName)}
              value={firstName}
              sx={{
                width: "100% !important",
                boxShadow: "3px 5px 4px -3px grey",
                borderRadius: 2,
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.input,
                },
              }}
            />
          </InputField>
          <InputField label={`${isChild ? "Child's " : ""}Last Name`}>
            <TextField
              id="outlined-textarea"
              inputRef={childLastNameRef}
              placeholder={`${isChild ? "Child's " : ""}Last Name`}
              onKeyDown={(e) => onKeyDown(e, childMobileRef)}
              onChange={(e) => onChangeTextField(e, setLastName)}
              value={lastName}
              sx={{
                width: "100% !important",
                boxShadow: "3px 5px 4px -3px grey",
                borderRadius: 2,
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.input,
                },
              }}
            />
          </InputField>
          {!isChild && (
            <InputField label="Mobile">
              <TextField
                id="outlined-textarea"
                inputRef={childMobileRef}
                value={formatIncompletePhoneNumber(mobile, "US")}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                placeholder="Mobile"
                sx={{
                  width: "100% !important",
                  boxShadow: "3px 5px 4px -3px grey",
                  borderRadius: 2,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.input,
                  },
                }}
              />
            </InputField>
          )}
          {!!error && (
            <InputField
              label={error}
              labelSx={{ color: "red", whiteSpace: "pre-wrap" }}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              sx={{
                color: "#fff",
                py: 2,
                px: 5,
                fontSize: { xs: 15, sm: 18 },
                borderRadius: 20,
                fontWeight: 700,
                background:
                  "linear-gradient(90deg, rgba(253,106,255,1) 0%, rgba(115,69,224,1) 100%)",
              }}
              className={classes.button}
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={25} />
              ) : (
                "Get Started"
              )}
            </Button>
          </Box>
        </Box>
        {otpModal && (
          <OTPModal
            onConfirm={onSubmitForm}
            onHide={() => setOTPModal(false)}
            open={otpModal}
            resendOTP={()=>resendOTP(setOTPModal(true))}
            isLoading={isLoading}
            token={token}
            timer={timer}
            setTimer={setTimer}
          />
        )}
        <div className={css.popover}>
          <div>
            By continuing, you agree to receive text messages from Cloud 9 Ignite,
            and agree to our{" "}
            <a
              href="https://toothfairytermsandconditions.s3-us-west-2.amazonaws.com/TermsOfUse.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="https://toothfairytermsandconditions.s3-us-west-2.amazonaws.com/PrivacyPolicy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            . Msg frequency varies. Msg & data rates may apply.
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default MarketingLandingPage;

const InputField = (props) => (
  <FormControl
    sx={{
      position: "relative",
      width: "calc( 100% - 16px ) !important",
      ...(props.sx || {}),
    }}
  >
    <InputLabel
      sx={{
        position: "relative",
        mb: { xs: 1, sm: 2 },
        fontSize: { xs: 17, sm: 19 },
        fontWeight: 600,
        color: "#7746e1",
        ...(props.labelSx || {}),
      }}
    >
      {props.label}
    </InputLabel>
    {props.children}
  </FormControl>
);

const StyledRadio = withStyles({
  root: {
    color: "#7746e1",
    "&.Mui-checked": {
      color: "#7746e1",
    },
  },
  input: {
    accentColor: "#7746e1",
  },
})(Radio);
