import React from "react";
import { FormControlLabel, styled, Switch } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { fontWeight } from "@mui/system";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "@media (max-width: 500px)": {
    width: 38,
    height: 22,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#7746e1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    "@media (max-width: 500px)": {
      width: 18,
      height: 18,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: "600 !important",
    marginLeft: "8px !important",
    fontSize: "19px !important",
    "@media (max-width: 500px)": {
      fontSize: "15px !important",
    },
  },
}));

const Toogle = ({ label, value, onChange = () => {} }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      checked={value}
      onChange={onChange}
      control={<IOSSwitch sx={{ m: { xs: 0.8, sm: 1 } }} />}
      label={label}
      sx={{ color: "#7746e1", fontWeight: 700, mr: 0 }}
      classes={{
        label: classes.label,
      }}
    />
  );
};

export default Toogle;
