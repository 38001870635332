import React from "react";

import cx from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import css from "./dialog.module.css";

import closeIcon from "../../images/close.png";

const useStyles = (theme) => ({
  root: {
    padding: 0,
    margin: "29px 40px 2px 40px",
    color: "#797979",
    fontSize: "20px",
    fontWeight: "900",
    // fontFamily: 'Avenir',
  },
  separator: {
    paddingBottom: "10px",
    borderBottom: "1px solid #797979",
  },
  closeButton: {
    position: "absolute",
    right: "2px",
    top: "2px",
  },
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { practiceid, children, classes, onClose, whiteCloseButton, ...other } =
    props;
  return (
    <MuiDialogTitle
      disableTypography
      className={cx(classes.root, children ? classes.separator : "")}
      {...other}
    >
      <span>{children}</span>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img
            className={whiteCloseButton ? css.whiteCloseButton : css.nothing}
            src={closeIcon}
            alt="close dialog"
          />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
