import React from "react";
import css from "./index.module.css";

export default function Adult(props) {
  return (
    <div>
      <div className={css.wrapper}>
        <div className={css.text}>
          Sorry for the inconvenience. You must be 18 or over to complete this
          questionnaire. Please call our office at 650-589-4563 if you need
          assistance. Thank you and we look forward to seeing you soon.
        </div>
      </div>
    </div>
  );
}
