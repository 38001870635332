import React, { useEffect } from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import css from "./index.module.css";
import { useState } from "../../state";
import SuccesModal from "../SuccesModal";

export default function Thanks() {
  const [sucessModal, setSucessModal] = React.useState();
  const [state] = useState();
  useEffect(() => {
    if (state?.isProspecting) setSucessModal(true);
  }, [state?.isProspecting]);

  return (
    <div className={css.container}>
      <div className={css.completed}>
        <CheckCircleOutlineIcon color="secondary" className={css.icon} />
        <div className={css.title}>Good Job!</div>
        <div className={css.title}>
          To prevent delays, bring the financial decision-maker to the
          appointment!
        </div>
      </div>
      {sucessModal && (
        <SuccesModal
          open={sucessModal}
          onClose={() => setSucessModal(false)}
          message={
            <>
              Thank you!
              <br /> Someone from the office will reach out to schedule your
              consultation.
            </>
          }
        />
      )}
    </div>
  );
}
