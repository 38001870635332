import React from "react";
import Slider from "react-input-slider";
import cx from "classnames";
import { useState } from "../../state";
import Question from "../Question";
import css from "./index.module.css";
import firstImg from "../../images/faces/1.png";
import secondImg from "../../images/faces/2.png";
import thirdImg from "../../images/faces/3.png";
import fourthImg from "../../images/faces/4.png";
import fifthImg from "../../images/faces/5.png";
import sixthImg from "../../images/faces/6.png";
import seventhImg from "../../images/faces/7.png";
import eigthImg from "../../images/faces/8.png";
import ninthImg from "../../images/faces/9.png";
import tenImg from "../../images/faces/10.png";

const styles = {
  track: {
    height: "500px",
    width: "15px",
    backgroundColor: "lightgrey",
    backgroundSize: "100%",
    borderRadius: "1000px",
  },
  active: {
    background:
      "linear-gradient(to bottom, #2894fe 50px ,#3cbdf2 100px ,#61d9c6 150px, #9be185 200px, #b9e77b 250px, #f1e981 300px, #fae282 350px, #fdcd81 400px, #feb180 450px, #fc9581 100%)",
    backgroundSize: "500px",
    borderRadius: "1000px",
  },
  thumb: {
    zIndex: 1000,
  },
};

const images = [
  null,
  firstImg,
  secondImg,
  thirdImg,
  fourthImg,
  fifthImg,
  sixthImg,
  seventhImg,
  eigthImg,
  ninthImg,
  tenImg,
];

const colors = [
  "nothing",
  "blue",
  "lightblue",
  "teal",
  "green",
  "lime",
  "yellow",
  "khaki",
  "sand",
  "orange",
  "red",
];


export default function Anxiety({ question }) {
  const [state, dispatch] = useState();

  return (
    <React.Fragment>
      <Question text={question.prompt} helpText={question.helper} />
      <div className={css.container}>
        <Slider
          styles={styles}
          axis="y"
          y={state.anxiety}
          ymin={1}
          ymax={10}
          ystep={1}
          onChange={({ y }) => dispatch({ type: question.id, value: y })}
        />
        <div className={css.faceIcons}>
          {[...colors.entries()].map(([y, color]) => (
            <div
              className={css.faceContainer}
              key={y}
              onClick={() => dispatch({ type: question.id, value: y })}
            >
              <div
                className={cx(css.line, { [css[color]]: state.anxiety === y })}
              />
              <div className={cx(css.face, { [css["nine"]]: y === 9 })}>
                {y > 0 ? (
                  <img src={images[y]} alt="logo" className={css.img} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className={css.numbers}>
          {[...colors.entries()].map(([y, color]) => (
            <div className={css.numberCell} key={y}>
              <div
                className={cx(
                  css.circle,
                  { [css["selected"]]: state.anxiety === y },
                  { [css[color]]: state.anxiety === y }
                )}
              >
                {y > 0 ? y : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
