import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Question from "../Question";
import Other from "../Other";
import { useState } from "../../state";

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: "20px auto"
  },
  root: {
    color: "#41a0fd",
    outline: "1px solid lightgrey",
    borderRight: "1px solid lightgrey",
    borderLeft: "1px solid lightgrey",
    padding: "10px 0",
    position: "relative",
    right: "-14px",
    minWidth: "80%"
  },
  checked: {
    backgroundImage: "linear-gradient(to right, #73ccff, #41a0fd)",
    color: "white"
  }
}));

export default function RadioList(props) {
  const question = props.section;
  const classes = useStyles();
  const [state, dispatch] = useState();

  const showOther = selection =>
    selection.toLowerCase().startsWith("other");

  function handleChange(event) {
    let selection = event.target.value;
    dispatch({ type: question.id, value: selection });
    if (showOther(selection)) return;
    selection = selection.toLowerCase();
    setTimeout(
      () => {
        if (typeof props.section.next === "string") {
          props.history.push(`/${props.section.next}`);
        } else if (props.section.next.default) {
          if (props.section.next[selection] === undefined) {
            props.history.push(`/${props.section.next.default}`);
          } else {
            props.history.push(
              `/${props.section.next[selection]}`
            );
          }
        } else {
          props.history.push(
            `/${props.section.next[selection]}`
          );
        }
      },
      200
    );
  }

  const handleOther = explanation => {
    dispatch({ type: `other_${question.id}`, value: explanation });
  };

  const radioItems = question.options.map(item => {
    return (
      <FormControlLabel
        className={cx(classes.root, {
          [classes.checked]: state[question.id] === item
        })}
        value={item}
        key={item}
        control={<Radio color="primary" />}
        label={item}
        labelPlacement="end"
      />
    );
  });

  return (
    <div>
      <FormControl component="fieldset">
        <Question text={question.prompt} helpText={question.helper} />
        <RadioGroup
          aria-label="position"
          name="position"
          value={state[question.id]}
          onChange={handleChange}
          className={classes.wrapper}
        >
          {radioItems}
        </RadioGroup>
      </FormControl>
      {showOther(state[question.id]) && (
        <Other question={question} handleOther={handleOther} />
      )}
    </div>

  );
}
