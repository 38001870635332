import React from "react";
import { Link, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RadioList from "./components/RadioList";
import CheckList from "./components/CheckList";
import FiveSlide from "./components/FiveSlide";
import Anxiety from "./components/Anxiety";
import SortableList from "./components/SortableList";
import InputText from "./components/InputText";
import Breadcrumbs from "./components/Breadcrumbs";
import Thanks from "./components/Thanks";
import Wedding from "./components/Wedding";
import Congrats from "./components/Congrats";
import Adult from "./components/Adult";
import { useState } from "./state";
import css from "./QuestionWrapper.module.css";
import Graduation from "./components/Graduation";

import { onSubmit } from "./lib";

const selectComponent = (props) => {
  const { section } = props;
  switch (section.component_type) {
    case "radio_button":
      return <RadioList {...props} />;
    case "checklist":
      return <CheckList question={section} />;
    case "input_text":
      return <InputText {...props} />;
    case "get_an_adult":
      return <Adult />;
    case "5_slide":
      return <FiveSlide question={section} />;
    case "emotion_slider":
      return <Anxiety question={section} />;
    case "sortable_list":
      return <SortableList question={section} />;
    case "wedding":
      return <Wedding {...props} />;
    case "graduation":
      return <Graduation {...props} />;
    case "congrats":
      return <Congrats {...props} />;
    case "thanks":
      return <Thanks />;
    default:
      return <React.Fragment />;
  }
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    "@media (max-width: 400px)": {
      fontSize: "18px",
    },
  },
  submitButton: {
    margin: theme.spacing(1),
    backgroundColor: "#efefef",
    padding: "5px 25px",
    "&:hover": {
      backgroundColor: "#65b8dc",
      color: "#fff",
    },
    "@media (max-width: 400px)": {
      fontSize: "18px",
    },
  },
  input: {
    display: "none",
  },
}));

const nextComponent = (state, id, next) => {
  if (next === undefined) {
    return "/";
  }
  if (typeof next === "string") {
    return `/${next}`;
  }

  if (Array.isArray(state[id])) {
    const nextEl = state[id][0];
    if (nextEl && next[nextEl.toLowerCase()] === undefined) {
      return next.default;
    } else {
      if (nextEl) {
        return next[nextEl.toLowerCase()];
      } else {
        return next.default;
      }
    }
  }

  if (next[state[id].toLowerCase()] === undefined) {
    return next.default;
  }

  return `/${next[state[id].toLowerCase()]}`;
};

export default function QuestionWrapper(props) {
  const classes = useStyles();
  const [state, dispatch] = useState();

  const buttonDisabled = () => {
    const id = window.location.pathname.slice(1);
    let missingRequired = false;

    if (props.section.required) {
      if (Array.isArray(state[id])) {
        if (state[id].length === 0) missingRequired = true;
      } else if (state[id] === undefined || !state[id]) missingRequired = true;
    }
    // Special required checks
    switch (id) {
      case "knowledge":
        let someKnowledge = state[id].findIndex((k) => k.value > 0);
        missingRequired = missingRequired || someKnowledge < 0;
        break;
      case "concerns":
        missingRequired =
          missingRequired ||
          (state.concerns.includes("Other emotional or physical concerns") &&
            (state.other_concerns === undefined ||
              state.other_concerns === ""));
        break;
      case "special_occasion":
        missingRequired =
          missingRequired ||
          (state.special_occasion === "Other" &&
            (state.other_special_occasion === undefined ||
              state.other_special_occasion === ""));
        break;
      case "referral":
        missingRequired =
          missingRequired ||
          (state.referral.includes("Other") &&
            (state.other_referral === undefined ||
              state.other_referral === ""));
        break;
      case "smile_concerns":
        missingRequired = missingRequired || !state?.smile_concerns?.trim();
        break;
      default:
        break;
    }
    return missingRequired || state[id] === "";
  };

  const handleFinishClick = async () => {
    onSubmit({ state, dispatch, props });
  };

  if (!state.listGroup && props.section.id !== "thanks")
    return <Redirect to="/" />;
  return (
    <div className={css.wrapper}>
      <Breadcrumbs {...props} />
      {selectComponent(props)}
      <div
        className={
          props.section.next === "thanks" ? css.footerSubmit : css.footer
        }
      >
        <hr className={css.hr} />
        <div className={css.navigation}>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => props.history.goBack()}
          >
            Back
          </Button>
          <Link
            to={nextComponent(state, props.section.id, props.section.next)}
            onClick={(e) => buttonDisabled() && e.preventDefault()}
            style={{ textDecoration: "none" }}
          >
            <Button
              color="primary"
              disabled={buttonDisabled()}
              className={
                props.section.next === "thanks"
                  ? classes.submitButton
                  : classes.button
              }
              onClick={handleFinishClick}
            >
              {props.section.next === "thanks" ? "Submit" : "Continue"}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
