import parsePhoneNumberFromString from "libphonenumber-js";

const present = (item) => {
  return !!item;
};

export const getErrorMessage = ({
  isChild,
  firstName,
  lastName,
  parentFirstName,
  parentLastName,
  mobile,
}) => {
  let phone = mobile && parsePhoneNumberFromString(mobile, "US");

  if (isChild && (!parentFirstName || !parentLastName))
    return "Please enter the Responsible Party's name";
  if (
    !(
      present(firstName) &&
      present(lastName) &&
      (!isChild || (present(parentFirstName) && present(parentLastName))) &&
      present(mobile)
    )
  )
    return "Please complete all form fields!";

  if (!phone || phone.nationalNumber.length !== 10)
    return "Please check phone number. Enter area code and 7 digit number.";

  return "";
};
