import React from "react";
import congrats from "../../images/congrats.png";
import css from "./index.module.css";

export default function Graduation(props) {
  setTimeout(() => props.history.push("/last_cleaning"), 2000);
  return (
    <div className={css.container}>
      <img src={congrats} alt="congrats" className={css.img} />
    </div>
  );
}
