import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Question from "../Question";
import { useState } from "../../state";
import css from "./index.module.css";

const SortableItem = SortableElement(({ value }) => (
  <li className={css.item}>
    <strong>{value.split(":")[0]}</strong>:{value.split(":")[1]}
  </li>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className={css.items}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </ul>
  );
});

export default function Sortable({ question }) {
  const [state, dispatch] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({
      type: question.id,
      value: arrayMove(state[question.id], oldIndex, newIndex)
    });
  };

  const items = state[question.id];

  return (
    <div className={css.component}>
      <Question text={question.prompt} helpText={question.helper} />
      <div className={css.container}>
        <div className={css.numbers}>
          {items.map((value, index) => (
            <div key={value} className={css.number}>
              {index + 1}
            </div>
          ))}
        </div>
        <SortableList items={items} onSortEnd={onSortEnd} />
      </div>
    </div>
  );
}
