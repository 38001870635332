import React from "react";
import cx from "classnames";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Question from "../Question";
import Other from "../Other";
import { useState } from "../../state";

const PrimaryCheckbox = withStyles({
  root: {
    color: "grey",
    "&$checked": {
      color: "blue",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: "20px auto",
    width: "100%",
    maxWidth: 360,
    borderLeft: "2px solid lightgrey",
    borderBottom: "2px solid lightgrey",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    borderTop: "2px solid lightgrey",
    borderRight: "2px solid lightgrey",
    color: "#41a0fd",
  },
  checked: {
    backgroundImage: "linear-gradient(to right, #73ccff, #41a0fd)",
    color: "white",
  },
}));

export default function CheckList({ question }) {
  const classes = useStyles();
  const [state, dispatch] = useState();

  const showOther = (selections) =>
    selections.some((el) => el.toLowerCase().startsWith("other"));

  const handleToggle = (value) => () => {
    const currentIndex = state[question.id].indexOf(value);
    const newChecked = [...state[question.id]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch({ type: question.id, value: newChecked });
  };

  const handleOther = (explanation) => {
    dispatch({ type: `other_${question.id}`, value: explanation });
  };

  return (
    <div>
      <FormControl component="fieldset">
        <Question text={question.prompt} helpText={question.helper} />
        <List className={classes.root}>
          {question.options.map((item) => {
            const labelId = `checkbox-list-label-${item}`;

            return (
              <ListItem
                key={item}
                role={undefined}
                button
                onClick={handleToggle(item)}
                className={cx(classes.item, {
                  [classes["checked"]]: state[question.id].includes(item),
                })}
              >
                <ListItemIcon>
                  <PrimaryCheckbox
                    edge="start"
                    checked={state[question.id].indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${item}`}
                  secondary={
                    question?.participatingPractice?.includes(item)
                      ? "(at participating practices only)"
                      : undefined
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </FormControl>
      {showOther(state[question.id]) && (
        <Other question={question} handleOther={handleOther} />
      )}
    </div>
  );
}
