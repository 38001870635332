export default function calculateScore(state) {
  // Deprecated: too bad... this was nice and clean
  // let anxietyScore = ((10 - state.anxiety) / 9)
  let anxietyScore;
  if (state.anxiety === 1) {
    anxietyScore = 1;
  } else if (state.anxiety >= 2 && state.anxiety < 6) {
    anxietyScore = 0.5;
  } else {
    anxietyScore = 0;
  }

  let howSoonScore;
  if (state.how_soon === "Today or ASAP") {
    howSoonScore = 1.0;
  } else if (state.how_soon === "In the next week or so") {
    howSoonScore = 0.8;
  } else if (state.how_soon === "Next month") {
    howSoonScore = 0.4;
  } else if (state.how_soon === "Not for a few months") {
    howSoonScore = 0.0;
  } else {
    throw new Error("Error parsing how soon score");
  }

  let lastCleaningScore;
  if (
    state.last_cleaning ===
    "Less than 6 months ago and the doctor gave me a great report"
  ) {
    lastCleaningScore = 1.0;
  } else if (
    state.last_cleaning ===
    "Less than 6 months ago but I still have outstanding dental work that needs to be completed"
  ) {
    lastCleaningScore = 0.5;
  } else {
    lastCleaningScore = 0.0;
  }

  let budgetScore = 0;
  if (
    state.financing.includes(
      "I don't have a down payment so I would like to know more about zero down payment options"
    )
  ) {
    budgetScore += 0.0;
  }

  if (
    state.financing.includes(
      "I would like to pay in full and take advantage of a special accounting discount"
    )
  ) {
    budgetScore += 1.0;
  }

  if (state.financing.includes("I have dental insurance")) {
    budgetScore += 0.33;
  }

  if (
    state.financing.includes("Insurance will cover a portion of my treatment")
  ) {
    budgetScore += 0.33;
  }

  if (
    state.financing.includes(
      "Based on my knowledge, I think I can put at least 25% down"
    )
  ) {
    budgetScore += 0.33;
  }

  if (state.financing.includes("I plan on using my credit card")) {
    budgetScore += 0.33;
  }

  if (
    state.financing.includes(
      "I plan on using my Health Savings or Flexible Spending Account money/card"
    )
  ) {
    budgetScore += 0.33;
  }

  if (state.financing.includes("I have a Care Credit card")) {
    budgetScore += 0.33;
  }

  if (budgetScore > 1.0) {
    budgetScore = 1.0;
  }

  const anxietyWeight = 0.1;
  const howSoonWeight = 0.1;
  const lastCleaningWeight = 0.2;
  const budgetWeight = 0.3;
  const baselineScore = 0.3;
  
  return Math.round(
    (anxietyScore * anxietyWeight +
      howSoonScore * howSoonWeight +
      lastCleaningScore * lastCleaningWeight +
      budgetScore * budgetWeight +
      baselineScore) *
      100
  );
}
