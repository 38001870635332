export default {
  version: 0,
  branding: "Invisalign",
  sections: [
    {
      id: "respondent_age",
      component_type: "radio_button",
      require: true,
      prompt:
        "The person completing this questionnaire is at least 18 years old?",
      options: ["Yes", "No"],
      next: { yes: "anxiety", no: "get_an_adult" },
    },
    {
      id: "get_an_adult",
      component_type: "get_an_adult",
    },
    {
      id: "anxiety",
      component_type: "emotion_slider",
      prompt:
        "Please rate your anxiety level when visiting your general dentist.",
      helper: "1 - not anxious to 10 - very anxious",
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      next: "how_soon",
    },
    {
      id: "how_soon",
      component_type: "radio_button",
      required: true,
      prompt: "How soon would you like to start Orthodontic treatment",
      options: [
        "Today or ASAP",
        "In the next week or so",
        "Next month",
        "Not for a few months",
      ],
      next: "treatment_aspects",
    },
    {
      id: "treatment_aspects",
      component_type: "sortable_list",
      prompt: "Please rank these treatment aspects",
      helper:
        "Most important on top, least important on bottom. Press and drag each card to reorder.",
      options: [
        "Aesthetics: I would prefer it if people don't notice that I'm in treatment",
        "Cost/Financing: I need the most flexible payment option available",
        "Time: I want to have a beautiful smile as quickly as possible",
        "Office Visit Efficiency: I want to come to the office as few times as possible and I'd like speedy appointments",
      ],
      next: "smile_concerns",
    },
    {
      id: "smile_concerns",
      component_type: "input_text",
      prompt: "What concerns you most about your smile?",
      helper: "",
      next: "knowledge",
    },
    {
      id: "knowledge",
      component_type: "5_slide",
      required: true,
      prompt:
        "How would you rate your knowledge level regarding the following Orthodontic treatment options that we offer?",
      helper: "0 - no knowledge, 5 - expert",
      options: [
        "Traditional metal braces",
        "Clear, tooth colored braces",
        "Removable clear aligners",
        "Lingual braces that go behind the teeth",
        "Accelerated Orthodontics - a treatment that can help speed up your treatment by 30%-50%",
      ],
      next: "concerns",
    },
    {
      id: "concerns",
      component_type: "checklist",
      required: true,
      prompt:
        "Do you experience any of the following that you would like the doctor to be aware of?",
      helper: "(choose all the apply)",
      options: [
        "Teasing from other people about the appearance of teeth",
        "Low self-confidence or self-esteem",
        "I prefer not to smile for pictures or show teeth",
        "Social anxiety",
        "Other emotional or physical concerns",
        "No concerns",
      ],
      next: "appeal",
    },
    {
      id: "appeal",
      component_type: "radio_button",
      required: true,
      prompt:
        "Without regard for cost, based on your current knowledge of orthodontic treatment options, which type of treatment is most appealing to you?",
      options: [
        "Clear aligners (removable for eating and brushing; ask for a complementary scan today)",
        "Metal braces",
        "Clear braces",
        "Lingual braces",
        "I just need retainers at this time",
      ],
      next: "special_occasion",
    },
    {
      id: "special_occasion",
      component_type: "radio_button",
      prompt: "Do you have a special occasion in the next two years?",
      required: true,
      options: [
        "Wedding",
        "Graduation",
        "Family pictures",
        "I am looking for a new job",
        "I am trying to get a promotion",
        "Other",
        "None. My life is pretty boring right now",
      ],
      next: {
        wedding: "wedding",
        graduation: "graduation",
        default: "last_cleaning",
      },
    },
    {
      id: "wedding",
      component_type: "wedding",
      prompt: "Congradulations on your ",
      next: "last_cleaning",
    },
    {
      id: "graduation",
      component_type: "graduation",
      prompt: "Congradulations on your ",
      next: "last_cleaning",
    },
    {
      id: "last_cleaning",
      component_type: "radio_button",
      required: true,
      prompt: "Your last dental cleaning and check up was...",
      options: [
        "Less than 6 months ago and the doctor gave me a great report",
        "Less than 6 months ago but I still have outstanding dental work that needs to be completed",
        "More than 6 months ago",
        "I cannot remember",
      ],
      next: {
        "less than 6 months ago and the doctor gave me a great report":
          "congrats",
        default: "financing",
      },
    },
    {
      id: "congrats",
      component_type: "congrats",
      prompt:
        "Congratulations, you may be able to start treatment the same day as your consultation.",
      next: "financing",
    },
    {
      id: "financing",
      component_type: "checklist",
      prompt: "Please describe your situation...",
      helper: "(choose all that apply)",
      required: true,
      options: [
        "I don't have a down payment so I would like to know more about zero down payment options",
        "I would like to pay in full and take advantage of a special accounting discount",
        "I have dental insurance",
        "Based on my knowledge, I think I can put at least 25% down",
        "I plan on using my credit card",
        "I plan on using my Health Savings or Flexible Spending Account money/card",
        "I have a Care Credit Card",
      ],
      participatingPractice: [
        "I don't have a down payment so I would like to know more about zero down payment options",
        "I have a Care Credit Card",
      ],
      next: "previous_work",
    },
    {
      id: "previous_work",
      component_type: "radio_button",
      require: true,
      prompt: "Have you previously had orthodontic treatment?",
      options: ["Yes", "No"],
      next: "referral",
    },
    {
      id: "referral",
      component_type: "checklist",
      prompt: "How did you hear about our practice?",
      helper: "(choose all that apply)",
      required: true,
      options: [
        "Dentist or Specialist",
        "Email Campaign",
        "Family",
        "Facebook/Instagram",
        "Friends",
        "Google",
        "Invisalign/Concierge",
        "Yelp",
        "Other",
      ],
      next: "thanks",
    },
    {
      id: "thanks",
      component_type: "thanks",
    },
  ],
};
