import React, { createContext, useContext, useReducer } from "react";
import { shuffle } from "../helpers";
import { isProduction } from "../envutils";

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const useState = () => useContext(StateContext);

export const StateInitializer = data => {
  let stateObj = {};
  let [guid, mobile, numTexts] = window.location.href.split("/").splice(-3, 3);
  const phoneRegex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
// console.log('State initializer listgroup',listGroup)
  if ((guid.length < 36) || !uuidRegex.test(guid)) {
    guid = uuidv4();
  }
  if (isNaN(parseInt(numTexts))) {
    numTexts = 1;
  } else {
    numTexts = parseInt(numTexts);
  }
  if (!phoneRegex.test(mobile)) // NA phone number with optional 1 before area code
    mobile = '';

  const info = { mobile };
  data.forEach(section => {
    switch (section.component_type) {
      case "checklist":
        stateObj[section.id] = [];
        break;
      case "radio_button":
        stateObj[section.id] = "";
        break;
      case "input_text":
        stateObj[section.id] = "";
        break;
      case "sortable_list":
        stateObj[section.id] = shuffle(section.options);
        break;
      case "5_slide":
        stateObj[section.id] = section.options.map(item => ({
          item: item,
          value: 0
        }));
        break;
      case "emotion_slider":
        stateObj[section.id] = 1;
        break;
      default:
        // console.log("How did we get here?");
    }
  });

  stateObj.info = info;
  stateObj.id = guid;
  stateObj.numTexts = numTexts;
  // console.log(stateObj);
  // stateObj.listGroup = listGroup
  return stateObj;
};
