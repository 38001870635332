import React from "react";
import css from "./PageNotFound.module.css";

const PageNotFound = () => {
  return (
    <div className={css.mainDiv}>
      404 <br />
      Page Not Found
    </div>
  );
};

export default PageNotFound;
