export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      dateCompleted
      firstName
      lastName
      score
      phoneNumber
      answers
      listGroup
      numTexts
      firstContact
      lastNudge
      status
      consultant
      location
      appointmentDate
      doctor
      motivation
      childMotivation
      timeline
      conversion
      stopper
      consult
      concierge
      unreadEmails
      unreadSMS
      lastNudgeTemplate
      noOfConversion
      convertedAt
      appointmentAttended
      consultationAttended
      updatedStatusComm
      createdAt
      updatedAt
      updatedUTC
      consultDate
      scripting
      patientRecalls
      recallHistory
      engagementStatistics
      consultNoShow
      appointmentNoShow
      isAppointmentCanceled
      isConsultationCanceled
      optedOut {
        status
        optOutBy
      }
      marketingPlatform
      hfdFinance {
        status
        totalAmount
        link
        referenceId
        createdAt
        accountId
        currency
        financeAmount
        termMonths
        monthlyPayment
        downPaymentAmount
      }
      isParentProgram
    }
  }
`;

export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      dateCompleted
      firstName
      lastName
      score
      phoneNumber
      answers
      listGroup
      numTexts
      firstContact
      lastNudge
      status
      consultant
      location
      appointmentDate
      doctor
      motivation
      childMotivation
      timeline
      conversion
      stopper
      consult
      concierge
      unreadEmails
      unreadSMS
      lastNudgeTemplate
      noOfConversion
      convertedAt
      appointmentAttended
      consultationAttended
      updatedStatusComm
      createdAt
      updatedAt
      updatedUTC
      consultDate
      scripting
      patientRecalls
      recallHistory
      engagementStatistics
      consultNoShow
      appointmentNoShow
      isAppointmentCanceled
      isConsultationCanceled
      optedOut {
        status
        optOutBy
      }
      marketingPlatform
      hfdFinance {
        status
        totalAmount
        link
        referenceId
        createdAt
        accountId
        currency
        financeAmount
        termMonths
        monthlyPayment
        downPaymentAmount
      }
      isParentProgram
      ptqResubmitingTime
    }
  }
`;

export const transactionCreateEvent = /* GraphQL */ `
  mutation TransactionCreateEvent(
    $listGroup: String!
    $event: CreateEventInput!
    $estimate: CreateEstimateInput
    $conversion: UpdateConversionInput
    $followup: FollowUpInput
  ) {
    transactionCreateEvent(
      listGroup: $listGroup
      event: $event
      estimate: $estimate
      conversion: $conversion
      followup: $followup
    ) {
      id
      listGroup
      eventDate
      patientId
      eventName
      consultant
      eventInfo
      numberOfSimultaneousEvents
      createdAt
      updatedAt
    }
  }
`;
