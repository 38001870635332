import { StateInitializer } from "./state";
import calculateScore from "./calculateScore";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { API, graphqlOperation } from "aws-amplify";
import {
  createPatient,
  transactionCreateEvent,
  updatePatient,
} from "./graphql/mutations";
import dataObject from "./networkStub";
import { v4 as uuidv4 } from "uuid";
import { generateRecallList, toTitleCase } from "./utils";
import moment from "moment";

export const onSubmit = async ({
  state,
  dispatch,
  isProspecting,
  history,
  props,
  platform,
  setLoading = () => {},
}) => {
  let normalizedFirstName = state.info.firstName.toLowerCase().trim();
  let normalizedLastName = state.info.lastName.toLowerCase().trim();
  let normalizedPhone = parsePhoneNumberFromString(
    state.info.mobile,
    "US"
  ).nationalNumber.trim();
  const email = state.info.email?.trim();
  const patientId = uuidv4();
  if (isProspecting) {
    const patientRecalls = JSON.stringify(generateRecallList());
    const payload = {
      id: patientId,
      firstName: normalizedFirstName,
      lastName: normalizedLastName,
      marketingPlatform: platform?.toLowerCase(),
      phoneNumber: normalizedPhone,
      numTexts: 1,
      status: "open",
      listGroup: state?.listGroup,
      patientRecalls,
      answers: {
        info: {
          email,
          firstName: normalizedFirstName,
          lastName: normalizedLastName,
          resPartyFirstName: state?.info?.resPartyFirstName,
          resPartyLastName: state?.info?.resPartyLastName,
          dob_month: state?.info?.dob_month,
          dob_day: state?.info?.dob_day,
          dob_year: state?.info?.dob_year,
          resPartyTitle: state?.info?.resPartyTitle,
        },
      },
    };
    payload.answers = JSON.stringify(payload.answers);
    try {
      await API.graphql(
        graphqlOperation(createPatient, {
          input: payload,
        })
      );
      await API.graphql(
        graphqlOperation(transactionCreateEvent, {
          listGroup: state.listGroup,
          event: {
            consultant: state?.consultant || "",
            eventName: `Marketing form received via ${toTitleCase(platform)}`,
            eventDate: new Date().toISOString(),
            eventInfo: JSON.stringify({}),
            listGroup: state.listGroup,
            patientId: patientId,
          },
        })
      );
      setLoading(false);
      history.push("/thanks");
    } catch (e) {}
  } else {
    let isPTQResubmiting =
      !!state?.ptqResubmitingTime &&
      (!isNaN(state?.ptqResubmitingTime) &&
        moment(+state?.ptqResubmitingTime).valueOf() > moment().valueOf());
    let answers = { ...state };
    delete answers.isProspecting;
    delete answers.listGroup;
    delete answers.consultant;
    delete answers.numTexts;
    delete answers.blockUser;
    delete answers.ptqResubmitingTime;
    delete answers.reset;

    if (props.section.next !== "thanks") return;

    let payload = {};
    payload.id = state.id;
    payload.dateCompleted = new Date().toISOString();
    payload.score = calculateScore(state);
    payload.answers = { ...answers };
    delete payload.answers.id;
    payload.answers.info.firstName = normalizedFirstName;
    payload.answers.info.lastName = normalizedLastName;
    payload.answers.info.phoneNumber = normalizedPhone;
    payload.answers.info.mobile = normalizedPhone; // can't have too many copies of the phone number!
    payload.answers.info.email = payload.answers.info.email.trim();
    payload.listGroup = state.listGroup;
    payload.phoneNumber = normalizedPhone;
    payload.numTexts = state.numTexts;
    payload.ptqResubmitingTime = "";

    if (payload.answers.referral?.includes("Invisalign/Concierge"))
      payload.concierge = true;

    payload.answers = JSON.stringify(payload.answers);

    await API.graphql(
      graphqlOperation(updatePatient, {
        input: payload,
      })
    );
    await API.graphql(
      graphqlOperation(transactionCreateEvent, {
        listGroup: payload.listGroup,
        event: {
          consultant: state?.consultant || "",
          eventName: isPTQResubmiting ? "PTQ updated" : "PTQ submitted",
          eventDate: new Date().toISOString(),
          eventInfo: JSON.stringify({ value: payload.score }),
          listGroup: payload.listGroup,
          patientId: payload.id,
        },
      })
    )
      .then(() => {
        setTimeout(() => {
          dispatch({
            type: "reset",
            payload: StateInitializer(dataObject.sections),
          });
        }, 3000);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }
};
