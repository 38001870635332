import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { getPatient, getPractice } from "./graphql/queries";
import { useState } from "./state";
import dataObject from "./networkStub";
import QuestionWrapper from "./QuestionWrapper";
import Welcome from "./components/Welcome";
import PageNotFound from "./PageNotFound";
import { validate } from "uuid";
import { Box, CircularProgress } from "@material-ui/core";
import MarketingLandingPage from "./components/MarketingLandingPage";

function LandingPage({ initialState, timer }) {
  const [isLoading, setLoading] = React.useState(true);
  const [state, dispatch] = useState();
  const history = useHistory();

  const func = async () => {
    const patientId = history?.location?.pathname.split("/")[1];
    const practiceName = history?.location?.pathname.split("/")[2];
    if (validate(patientId)) {
      const guid = patientId;
      let patient = await API.graphql(
        graphqlOperation(getPatient, {
          id: guid,
        })
      );
      if (!!patient?.data?.getPatient) {
        dispatch({
          type: "patient_details",
          payload: patient?.data?.getPatient,
        });
        await getPracticeDetails(patient?.data?.getPatient?.listGroup);
        setLoading(false);
      } else history.push("/404");
    } else {
      const marketingPlatforms = ["yelp", "facebook", "google"];
      if (marketingPlatforms.includes(patientId?.toLowerCase())) {
        await getPracticeDetails(practiceName, true);
      } else history.push("/404");
      setLoading(false);
    }
  };

  const getPracticeDetails = async (practiceId, isMarketingUser) => {
    let practice = await API.graphql(
      graphqlOperation(getPractice, {
        id: practiceId,
      })
    );
    if (practice?.data?.getPractice) {
      dispatch({
        type: "practice_details",
        payload: { ...practice?.data?.getPractice, isMarketingUser },
      });
    } else history.push("/404");
    return true
  };

  useEffect(() => {
    func();
  }, []);

  if (isLoading)
    return (
      <Box
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div>
      <Switch>
        <Route path={"/404"} component={PageNotFound} />
        {dataObject.sections.map((section) => (
          <Route
            path={`/${section.id}`}
            key={section.id}
            render={(routeProps) => (
              <QuestionWrapper
                initialState={initialState}
                timer={timer}
                {...routeProps}
                section={section}
              />
            )}
          />
        ))}
        <Route
          path={"/:id"}
          exact
          component={Welcome}
        />
        <Route
          path={"/:platform/:id"}
          exact
          render={(routeProps) => <MarketingLandingPage {...routeProps} isProspecting />}
        />
      </Switch>
    </div>
  );
}

export default LandingPage;
