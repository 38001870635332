import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Question from "../Question";
import { useState } from "../../state";
import css from "./index.module.css";

const PrettoSlider = withStyles({
  root: {
    color: "#2591FE",
    margin: "6px 0"
  },
  thumb: {
    height: "24px",
    width: "24px",
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

export default function FiveSlide({ question }) {
  const [state, dispatch] = useState();
  const questions = [...state[question.id]];

  const handleChange = (id, value) => {
    for (let item in questions) {
      if (questions[item].item === id) {
        questions[item].value = value;
      }
    }

    dispatch({ type: question.id, value: questions });
  };

  return (
    <div>
      <Question text={question.prompt} helpText={question.helper} />
      <div className={css.sliders}>
        {questions.map(({ item, value }) => (
          <React.Fragment key={item}>
            <Typography>{item}</Typography>
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              value={value}
              step={1}
              min={0}
              max={5}
              onChange={(_event, value) => handleChange(item, value)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
