import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { StateProvider, StateInitializer } from "./state";
import { reducer } from "./state/reducer";
import Amplify from "aws-amplify";
import LandingPage from "./landingPage";
import dataObject from "./networkStub";

Amplify.configure({
  aws_appsync_graphqlEndpoint:process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
  aws_appsync_authenticationType: "API_KEY",
});

const App = () => {
  const initialState = StateInitializer(dataObject.sections);
  let timer = 300;
  const startTimer = () => {
    return setInterval(() => {
      timer = timer - 1;
    }, 1000);
  };

  let masterTimer = startTimer();

  const handleAppClick = (e) => {
    timer = 300;
    clearInterval(masterTimer);
    masterTimer = startTimer();
  };

  return (
    <div onClick={handleAppClick}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router history={createBrowserHistory()}>
          <LandingPage initialState={initialState} timer={timer} />
        </Router>
      </StateProvider>
    </div>
  );
};

export default App;
