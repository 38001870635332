import React from "react";
import css from "./index.module.css";

export default function Question({ text, helpText }) {
  return (
    <div className={css.container}>
      <div className={css.text}>{text}</div>
      <div className={css.helpText}>{helpText}</div>
    </div>
  );
}
