import { toTitleCase } from "../utils";

export const reducer = (state, action) => {
  if (action?.type === "reset") {
    return { ...action?.payload };
  }
  if (action?.type === "patient_details")
    return {
      ...state,
      id: action?.payload?.id,
      info: {
        mobile: action?.payload?.phoneNumber,
        firstName: toTitleCase(action?.payload?.firstName),
        lastName: toTitleCase(action?.payload?.lastName),
        numTexts: action?.payload?.numTexts,
      },
      consultant: action?.payload?.consultant,
      listGroup: action?.payload?.listGroup,
      numTexts: action?.payload?.numTexts,
      blockUser: action?.payload?.score,
      location: action?.payload?.location,
      status: action?.payload?.status,
      ptqResubmitingTime: action?.payload?.ptqResubmitingTime,
    };
  if (action?.type === "practice_details") {
    const childPractice =
      state?.location &&
      action?.payload?.locations?.find(
        (item) => item?.id === state?.location && item?.isChildPractice
      );
    return {
      ...state,
      listGroup: action?.payload?.id,
      isMarketingUser: action?.payload?.isMarketingUser,
      practiceDisplayName: childPractice
        ? childPractice?.fullName
        : action?.payload?.displayName,
      logo: childPractice ? childPractice?.logoUrl : action?.payload?.logo,
    };
  }
  if (action.type) {
    return {
      ...state,
      [action.type]: action.value,
    };
  }

  return state;
};
