import React, { useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import ThumbsUp from "../../images/thumbs-up-icon.png";

import css from "./index.module.css";

export default function SuccesModal({
  open,
  message = "Update",
  onClose = () => {},
}) {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 4000);
  }, []);

  return (
    <Dialog open={open} disableBackdropClick>
      <div className={css.dialogContent}>
        <div className={css.mainDiv}>
          <img className={css.thumbs} src={ThumbsUp} alt="thumbs-up" />
          <p className={css.txt}>{message}</p>
        </div>
      </div>
    </Dialog>
  );
}
